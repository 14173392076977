import React from 'react'
import { graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Components
import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query CommunityPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    community: sanityCommunity(_id: { regex: "/(drafts.|)communityPage/" }) {
      title
      title1
      body
      soon
      earnCrypto
      earnDescription
      earnImage {
        asset {
          _id
        }
      }
      join
      joinDescription
      joinImage {
        asset {
          _id
        }
      }
      buttonCta
      buttonLink
    }
  }
`

// Classes
const HeadingClass = 'dib relative w-100 w-60-ns f2 fw5 mt0 mb2'
const ParagraphClass = 'dib relative w-100 measure-wide gray fw4 mb4'
const ButtonClass = 'dib relative w-100 w-auto-l brand-red tc ph3 pv2 br-pill b link dim pointer'

const CommunityPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const community = (data || {}).community

  if (!community) {
    throw new Error(
      'Missing "Community" page data. Open the studio at http://localhost:3333 and add "Community" page data and restart the development server.'
    )
  }

  const DiscordButton = () => (
    <a className={`bg-brand-pink ${ButtonClass}`} target="_blank" href={community.buttonLink}>
      <img className="dib relative v-mid mr3" src="/icons/social/discord.svg" />
      {community.buttonCta}
    </a>
  )

  return (
    <Layout>
      <SEO title={community.title} description={community.body} keywords={site.keywords} />
      <article>
        {/* Community main */}
        <section
          className="dt relative vh-100 w-100 bg-gradient-3 black lh-copy overflow-hidden f5"
          id="about"
        >
          <div className="dtc v-mid w-100 relative">
            <PageHeader description={community.body} heading={community.title1} href="#earn" />
          </div>
        </section>

        {/* Earn */}
        <section className="dib relative w-100 bg-white black lh-copy f5 overflow-hidden" id="earn">
          <div className="db center mw8 ph4 pt6 pb6">
            {community.soon ? (
              <strong className="dib reative w-100 f4 mb4 brand-orange">Coming soon</strong>
            ) : null}
            <h2 className={HeadingClass}>{community.earnCrypto}</h2>
            <p className={`${ParagraphClass} preline`}>{community.earnDescription}</p>
            <div className="dib relative w-100 mb4">
              <DiscordButton />
            </div>
            <img
              className="dib relative w-100"
              src={imageUrlFor(buildImageObj(community.earnImage))}
              alt={community.earnCrypto}
            />
          </div>
        </section>

        {/* Wait */}
        <section className="dib relative w-100 bg-black white lh-copy f5 overflow-hidden" id="wait">
          <div className="db center mw8 ph4 pt6 pb4">
            <h3 className={HeadingClass}>{community.join}</h3>
            <p className={`${ParagraphClass} preline`}>{community.joinDescription}</p>
            <div className="dib relative w-100 mb4">
              <DiscordButton />
            </div>
            <img
              className="dib relative w-100"
              src={imageUrlFor(buildImageObj(community.joinImage))}
              alt={community.join}
            />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default CommunityPage
